<template>
<div>
  <v-fab-transition>
    <v-btn color="grey darken-3" class="stemp-time-runner my-4" v-if="isTimerRunning" depressed @click="$router.push('/stemp')" fab dark top right>{{time}}</v-btn>
  </v-fab-transition>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import TimerMixin from '../mixins/timer'
export default {
  mixins: [TimerMixin],
  data () {
    return {
      time: '00:00:00',
      webWorkerInstance: null,
      isTimerRunning: false
    }
  },
  computed: {
    ...mapGetters(['getuserDetails'])
  },
  mounted () {
    this.getRunningStemp()
    // let isRunning = window.localStorage.getItem('is_timer_running') || 'false'
    // if (isRunning === 'true') this.getRunningStemp()
  },
  watch: {
    'time' (val) {
      if (this.$store.state.common.notifiedCount === 0) this.$eventBus.$emit('checkHoursExceeding')
    }
  }
}
</script>
<style>
.stemp-time-runner {
  border-radius: 2px;
  padding: 5px 10px;
  height: auto !important;
  width: auto !important;
  top: 5px !important;
  right: 5px !important;
}
</style>
